@import '/styles/vars';

.panel {
    // box-shadow: 0 0 60px 0 rgba(21, 55, 56, 0.04);
    // box-shadow: 0 0 60px 0 rgba(21, 55, 56, 0.04);
    border: $dashed;

    padding: $gap36;
    margin-left: auto;
    margin-right: auto;
    border-radius: $radius3;
    position: relative;
    width: 100%;
    background: $color_bg;
    @include mobile {
        padding: $gap24;
    }
}

.panelDesktop {
    @include mobile {
        padding: 0;
        border: 0;
        box-shadow: none;
    }
}

.small {
    max-width: 560px;
    text-align: center;
    h1 {
        margin: 0 0 $gap36 0;
        + p {
            margin: -$gap12 0 $gap36 0;
        }
    }
    footer {
        margin: $gap48 auto 0 auto;
        max-width: 400px;
        p {
            margin: 1.5em auto 0 auto;
        }
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        @include mobile {
            margin: $gap30 auto 0 auto;
        }
    }
}
