@import '/styles/vars';

.rich {
    h1,
    h2,
    h3,
    h4,
    b,
    strong {
        color: $color_dark;
        font-weight: $bold;
    }
    h1,
    h2,
    h3 {
        a {
            text-decoration: none;
        }
    }
    h1 {
        font-size: $font36;
        line-height: 1.333;
        margin: 0 0 1.33em 0;
    }
    h2 {
        font-size: $font24;
        line-height: 1.375;
        margin: 1.6em 0 0.8em 0;
    }
    h3 {
        font-size: $font18;
        line-height: 1.333;
        margin: 1.6em 0;
    }
    a {
        text-decoration: underline;
        strong,
        b {
            text-decoration: underline;
            color: $color_green;
            font-weight: $bold;
        }
        &:hover {
            text-decoration: none;
            strong,
            b {
                text-decoration: none;
            }
        }
    }
    ol,
    ul {
        padding: 0;
    }
    li {
        list-style: none;
    }
    img {
        width: 100%;
        height: auto;
    }
    ul,
    ol {
        margin: 0.8em 0 1.6em 0;
        li {
            padding-left: 1.6em;
            margin: 0.3em 0;
            position: relative;
            counter-increment: item;
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            &:before {
                content: counters(item, '.') '. ';
                position: absolute;
                left: 0;
                color: $color_accent;
                font-weight: $bold;
            }
        }
    }
    ul li:before {
        font-size: 1.3em;
        content: '•';
        margin-top: -0.2em;
    }
    p {
        margin: 0.8em 0;
    }
    table {
        margin: 1.5em 0;
        border-collapse: collapse;
        border: $border;
        font-size: 80%;
        width: 100%;
        * {
            font-size: $font14;
        }
        p {
            margin: 0;
        }
        td,
        th {
            border: $border;
            padding: $gap12;
        }
        td:first-child {
            border-left: 0px solid transparent;
        }
    }
    @include mobile {
        h1 {
            font-size: $font24;
            line-height: 1.25;
            margin: 0 0 1.6em 0;
        }
        h2 {
            font-size: $font18;
            line-height: 1.333;
            margin: 1.6em 0 0.8em 0;
        }
        h3 {
            font-size: $font18;
            line-height: 1.333;
            margin: 0 0 1.6em 0;
        }
    }
}

.alt {
    a {
        color: $color_red;
    }
    h2,
    li {
        a {
            color: inherit;
        }
    }
}
