@import '/styles/vars';

html {
    .page {
        max-width: 1018px;
        margin: 0 auto;
    }

    .panel {
        margin-top: 24px;
        @include tablet {
            margin-top: 0;
        }
    }
}
